import { lazy } from "react";
import { Navigate, useNavigate, useNavigation } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import materialRoutes from "app/views/material-kit/MaterialRoutes";
import ResetPassword from "./views/sessions/ResetPassword";
import UsersStore from "./user/userManagement/UsersStore";
import MainPageWrapper from "./MainPageWrapper";
import appStore from "./mobxStore/AppStore";
import Login from "./views/sessions/Login";

// session pages
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(
  lazy(() => import("app/views/sessions/JwtRegister"))
);
const ForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/ForgotPassword"))
);

// echart page
const AppEchart = Loadable(
  lazy(() => import("app/views/charts/echarts/AppEchart"))
);

// dashboard page
//const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));

const Analytics = Loadable(lazy(() => import("app/user/dashboard/Dashboard")));
//Document Form Page
const DocumentFormPage = Loadable(lazy(() => import("app/user/DocumentForm")));
const TaskPanelPage = Loadable(lazy(() => import("app/user/tasks/TaskPanel")));
const MyTasksPage = Loadable(lazy(() => import("app/user/tasks/TasksPage")));
const UsersPage = Loadable(lazy(() => import("app/user/userManagement/Users")));
const DocumentsViewPage = Loadable(
  lazy(() => import("app/user/documents/DocumentsView"))
);
const GetTaskNoData = Loadable(lazy(() => import("api/GetTaskNoData.tsx")));
const SomethingWentWrong = Loadable(
  lazy(() => import("api/SomethingWentWrong"))
);

// Split the path to extract the ID
export const getRoutes = (path) => {
  if (path == "/**") {
    appStore.setShowEntityDialog(true);
    path = "/";
  }
  const latestTasks = [
    { id: 1, name: "Task 1" },
    { id: 2, name: "Task 2" },
    { id: 3, name: "Task 3" },
    { id: 4, name: "Task 4" },
    { id: 5, name: "Task 5" },
  ];

  const oldestTasks = [
    { id: 96, name: "Task 96" },
    { id: 97, name: "Task 97" },
    { id: 98, name: "Task 98" },
    { id: 99, name: "Task 99" },
    { id: 100, name: "Task 100" },
  ];
  const routes = [
    {
      element: (
        <AuthGuard>
          <MatxLayout />
        </AuthGuard>
      ),
      children: [
        ...materialRoutes,
        // dashboard route
        {
          path: "/dashboard/default",
          element: (
            <MainPageWrapper
              children={
                <Analytics
                  latestTasks={latestTasks}
                  oldestTasks={oldestTasks}
                  totalTasks={100}
                />
              }
            />
          ),
          auth: authRoles.admin,
        },
        {
          path: `/user/document/:did`,
          element: <MainPageWrapper children={<DocumentFormPage />} />,
        },
        {
          path: `/user/documents`,
          element: <MainPageWrapper children={<DocumentsViewPage />} />,
        },
        // e-chart rooute
        {
          path: "/charts/echarts",
          element: <MainPageWrapper children={<AppEchart />} />,
          auth: authRoles.editor,
        },
        {
          path: "/user/task/:id",
          element: <MainPageWrapper children={<TaskPanelPage />} />,
        },
        {
          path: "/user/tasks:id",
          element: <MainPageWrapper children={<MyTasksPage />} />,
        },
        {
          path: "/user/users",
          element: <MainPageWrapper children={<UsersPage />} />,
        },
        {
          path: "/user/tasks",
          element: <MainPageWrapper children={<MyTasksPage />} />,
        },
        {
          path: "/Error/SomethingWentWrong",
          element: <MainPageWrapper children={<SomethingWentWrong />} />,
        },
        {
          path: "/No-Found-Data/:id",
          element: <MainPageWrapper children={<GetTaskNoData />} />,
        },
      ],
    },

    // session pages route
    { path: "/session/404", element: <NotFound /> },
    { path: "/session/signin", element: <JwtLogin /> },
    { path: "/session/signup", element: <JwtRegister /> },
    { path: "/session/forgot-password", element: <ForgotPassword /> },
    { path: "/session/reset-password/:urlText", element: <ResetPassword /> },
    { path: "/", element: <Navigate to="dashboard/default" /> },
    { path: "*", element: <NotFound /> },
  ];
  return routes;
};
